import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { createBrowserHistory } from 'history';
import { routerMiddleware, connectRouter, ConnectedRouter } from 'connected-react-router';

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { Helmet } from 'react-helmet';

import './index.css';

import RootReducer from './state/rootReducer';

import theme from './theme';
import App from './components/app';


const history = createBrowserHistory();

/* eslint-disable no-underscore-dangle */
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-disable no-underscore-dangle */

const enhancer = composeEnhancer(
  applyMiddleware(thunk, routerMiddleware(history)),
);

const store = createStore(connectRouter(history)(RootReducer), enhancer);

const render = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <Helmet>
          <title>Draft Goals</title>
        </Helmet>
        <App />
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(render(), document.getElementById('root'));
