import {
  DOWNLOAD_PLAYERS,
  DOWNLOAD_PLAYERS_COMPLETE,
  DOWNLOAD_PLAYERS_ERROR,
  DOWNLOAD_FIXTURES,
  DOWNLOAD_FIXTURES_COMPLETE,
  DOWNLOAD_FIXTURES_ERROR,
  DOWNLOAD_SQUADS,
  DOWNLOAD_SQUADS_COMPLETE,
  DOWNLOAD_SQUADS_ERROR,
} from './actions';

const defaultState = {
  players: null,
  fixtures: null,
  squads: null,
  error: false,
};

const dataReducer = (state = defaultState, action) => {
  switch (action.type) {
    case DOWNLOAD_PLAYERS: {
      return { ...state, players: null };
    }
    case DOWNLOAD_FIXTURES: {
      return { ...state, fixtures: null };
    }
    case DOWNLOAD_SQUADS: {
      return { ...state, squads: null };
    }
    case DOWNLOAD_PLAYERS_COMPLETE: {
      return { ...state, players: action.payload };
    }
    case DOWNLOAD_FIXTURES_COMPLETE: {
      return { ...state, fixtures: action.payload };
    }
    case DOWNLOAD_SQUADS_COMPLETE: {
      return { ...state, squads: action.payload.managers };
    }
    case DOWNLOAD_PLAYERS_ERROR:
    case DOWNLOAD_FIXTURES_ERROR:
    case DOWNLOAD_SQUADS_ERROR: {
      return { ...state, error: true };
    }
    default: {
      return state;
    }
  }
};

export default dataReducer;
