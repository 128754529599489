import {
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
} from './actions';

const defaultState = {
  isOpen: false,
  message: '',
};

const snackbarReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_SNACKBAR: {
      const { message } = action;
      return { ...state, message, isOpen: true };
    }
    case CLOSE_SNACKBAR: {
      return { ...state, isOpen: false };
    }
    default: {
      return state;
    }
  }
};

export default snackbarReducer;
