import { getPlayerData, getSquads, getFixtures } from '../../services/dataService';

export const DOWNLOAD_PLAYERS = 'DOWNLOAD_PLAYERS';
export const DOWNLOAD_PLAYERS_COMPLETE = 'DOWNLOAD_PLAYERS_COMPLETE';
export const DOWNLOAD_PLAYERS_ERROR = 'DOWNLOAD_PLAYERS_ERROR';

export const DOWNLOAD_FIXTURES = 'DOWNLOAD_FIXTURES';
export const DOWNLOAD_FIXTURES_COMPLETE = 'DOWNLOAD_FIXTURES_COMPLETE';
export const DOWNLOAD_FIXTURES_ERROR = 'DOWNLOAD_FIXTURES_ERROR';

export const DOWNLOAD_SQUADS = 'DOWNLOAD_SQUADS';
export const DOWNLOAD_SQUADS_COMPLETE = 'DOWNLOAD_SQUADS_COMPLETE';
export const DOWNLOAD_SQUADS_ERROR = 'DOWNLOAD_SQUADS_ERROR';


export const downloadPlayers = () => (dispatch) => {
  dispatch({
    type: DOWNLOAD_PLAYERS,
  });

  getPlayerData()
    .then((players) => {
      dispatch({
        type: DOWNLOAD_PLAYERS_COMPLETE,
        payload: players,
      });
    })
    .catch(() => {
      dispatch({ type: DOWNLOAD_PLAYERS_ERROR });
    });
};

export const downloadSquads = () => (dispatch) => {
  dispatch({
    type: DOWNLOAD_SQUADS,
  });

  getSquads()
    .then((squads) => {
      dispatch({
        type: DOWNLOAD_SQUADS_COMPLETE,
        payload: squads,
      });
    })
    .catch(() => {
      dispatch({ type: DOWNLOAD_SQUADS_ERROR });
    });
};

export const downloadFixtures = () => (dispatch) => {
  dispatch({
    type: DOWNLOAD_FIXTURES,
  });

  getFixtures()
    .then((fixtures) => {
      dispatch({
        type: DOWNLOAD_FIXTURES_COMPLETE,
        payload: fixtures,
      });
    })
    .catch(() => {
      dispatch({ type: DOWNLOAD_FIXTURES_ERROR });
    });
};
