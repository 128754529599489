import axios from 'axios';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

const apiRoot = 'https://belterblobby.blob.core.windows.net/draft/';
const getRequestDate = () => format(new Date(), 'yyyyMMddHHmm');

export const getPlayerData = () => axios.get(`${apiRoot}combine.json?d=${getRequestDate()}`)
  .then(response => response.data);

export const getSquads = () => axios.get(`${apiRoot}squad.json?d=${getRequestDate()}`)
  .then(response => response.data);

export const getFixtures = () => axios.get(`${apiRoot}fixtures.json?d=${getRequestDate()}`)
  .then(response => response.data.map(fixture => ({ ...fixture, kickoff_time: parseISO(fixture.kickoff_time) })));

export default getPlayerData;
