import React, { Component } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { connect } from 'react-redux';
import Squad from './squad';
import { getIsSnackbarOpen, getSnackbarMessage } from '../state/snackbar/selectors';
import { getPlayers, getSquads } from '../state/data/selectors';

class Squads extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    const {
      players, squads,
    } = this.props;

    return (
      <div>
        {
         orderBy(squads, ['totalGoals', 'name'], ['desc', 'asc'])
           .map(manager => <Squad key={manager.name} manager={manager} players={players} />)
        }
      </div>
    );
  }
}

Squads.propTypes = {
  players: PropTypes.shape({}).isRequired,
  squads: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  isSnackbarOpen: getIsSnackbarOpen(state),
  snackbarMessage: getSnackbarMessage(state),
  players: getPlayers(state),
  squads: getSquads(state),
});


export default connect(mapStateToProps)(Squads);
