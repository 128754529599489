import React from 'react';
import head from 'lodash/head';
import Warning from '@material-ui/icons/Warning';

const teams = require('../teams.json');

export const getGoalsForMe = (players, pick) => {
  const player = players[pick.id];
  if (player.goals_scored === 0) return 0;
  const goals = player.goals.filter(match => match.date > pick.in && (!pick.out || pick.out > match.date));
  return goals.reduce((acc, match) => match.number + acc, 0);
};

export const getTotalGoals = (players, squad) => squad.reduce((acc, pick) => acc + getGoalsForMe(players, pick), 0);

export const getTeamBadge = teamId => `/crests/${teams[teamId].short_name}.png`;

export const getTeamName = teamId => teams[teamId].name;

export const getCurrentManager = (squads, player) => {
  const ss = Object.values(squads);
  const squad = head(ss.filter(s => s.squad.some(pick => !pick.out && pick.id === player.id)));
  return squad ? squad.name : null;
};

export const getPosition = (player) => {
  switch (player.element_type) {
    case 1:
      return 'Goalkeeper';
    case 2:
      return 'Defender';
    case 3:
      return 'Midfielder';
    case 4:
      return 'Forward';
    default:
      return 'Unknown Position';
  }
};

export const getAvailability = ({ status }) => {
  switch (status) {
    case 'a':
      return null;
    case 'i':
    case 'u':
    case 'n':
    case 's':
      return <Warning color="error" />;
    case 'd':
      return <Warning className="warning" />;
    default:
      return <Warning color="secondary" />;
  }
};

export const getPlayerName = ({ name, first_name, second_name }) => {
  if (name !== second_name) {
    return name;
  }
  return `${first_name} ${second_name}`;
};
