import groupBy from 'lodash/groupBy';
import format from 'date-fns/format';
import { getTotalGoals } from '../../helpers/playerHelpers';

export const getFixtures = (state) => {
  const fixturesGrouped = groupBy(state.data.fixtures, fixture => format(fixture.kickoff_time, 'yyyy-MM-dd'));
  return fixturesGrouped;
};

export const getPlayers = state => state.data.players;

export const getSquads = state => state.data.squads.map(manager => ({ ...manager, totalGoals: getTotalGoals(getPlayers(state), manager.squad) }));

export const getSquadByManagerName = (state, managerName) => getSquads(state).filter(m => m.name === managerName)[0];

export const getSearchablePlayers = state => Object.keys(state.data.players).map(playerId => state.data.players[playerId]).filter(player => player.element_type > 1 && player.status !== 'u');

export const getIsDataLoaded = state => state.data.players && state.data.squads && state.data.fixtures && !state.data.error;

export const getPlayer = (state, playerId) => state.data.players[playerId];
