import React, { Component, Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Route, Switch, withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getIsDataLoaded } from '../state/data/selectors';
import { downloadPlayers, downloadFixtures, downloadSquads } from '../state/data/actions';
import { getIsSnackbarOpen, getSnackbarMessage } from '../state/snackbar/selectors';
import { closeSnackbar } from '../state/snackbar/actions';

// ROUTES
import Squads from './squads';
const Fixtures = lazy(() => import('./fixtures'));
const Player = lazy(() => import('./player'));
const AllPlayers = lazy(() => import('./allPlayers'));

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
  }

  componentDidMount() {
    const { loadData } = this.props;
    loadData();
  }

  handleDrawerOpen() {
    this.setState({ drawerOpen: true });
  }

  handleDrawerClose() {
    this.setState({ drawerOpen: false });
  }

  handleSnackbarClose() {
    const { closeSnackbar } = this.props;
    closeSnackbar();
  }

  render() {
    const {
      dataLoaded, history, isSnackbarOpen, snackbarMessage,
    } = this.props;
    const { drawerOpen } = this.state;
    return (
      <div>
        <AppBar position="fixed">
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Draft Goals
            </Typography>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          open={drawerOpen}
          onClose={this.handleDrawerClose}
          onOpen={this.handleDrawerOpen}
        >
          <List disablePadding>
            <ListItem button onClick={() => { history.push('/'); this.handleDrawerClose(); }}>
              <ListItemText primary="Squads" />
            </ListItem>
            <ListItem button onClick={() => { history.push('/fixtures'); this.handleDrawerClose(); }}>
              <ListItemText primary="Fixtures" />
            </ListItem>
            <ListItem button onClick={() => { history.push('/players'); this.handleDrawerClose(); }}>
              <ListItemText primary="All Players" />
            </ListItem>
          </List>
        </SwipeableDrawer>
        { !dataLoaded
          ? <LinearProgress color="secondary" />
          : (
            <Suspense fallback={() => <div>Loading</div>} >
              <Switch>
                <Route exact path="/" component={Squads} />
                <Route exact path="/fixtures" component={Fixtures} />
                <Route path="/player/:id" component={Player} />
                <Route exact path="/players" component={AllPlayers} />>
              </Switch>
            </Suspense>
          )
        }
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleSnackbarClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{snackbarMessage}</span>}
          action={[
            <IconButton
              onClick={this.handleSnackbarClose}
              key="close"
              aria-label="Close"
              color="inherit"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  dataLoaded: getIsDataLoaded(state),
  isSnackbarOpen: getIsSnackbarOpen(state),
  snackbarMessage: getSnackbarMessage(state),


});

const mapDispatchToProps = dispatch => ({
  loadData: () => {
    dispatch(downloadPlayers());
    dispatch(downloadSquads());
    dispatch(downloadFixtures());
  },
  closeSnackbar: () => dispatch(closeSnackbar()),
});

App.defaultProps = {
  dataLoaded: false,
};

App.propTypes = {
  dataLoaded: PropTypes.bool,
  loadData: PropTypes.func.isRequired,
  isSnackbarOpen: PropTypes.bool.isRequired,
  snackbarMessage: PropTypes.string.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
