import { combineReducers } from 'redux';
import snackbarReducer from './snackbar/reducer';
import dataReducer from './data/reducer';

const RootReducer = combineReducers({
  snackbar: snackbarReducer,
  data: dataReducer,
});

export default RootReducer;
