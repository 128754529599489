import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

import {
  getGoalsForMe, getTeamBadge, getAvailability, getPlayerName,
} from '../helpers/playerHelpers';
import { openSnackbar } from '../state/snackbar/actions';

const getActivePicks = (squad) => {
  const result = squad.filter(player => !player.out);
  return result;
};

const getInactivePicks = (squad) => {
  const result = squad.filter(player => player.out);
  return result;
};

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  listSubheader: {
    backgroundColor: '#1976D2',
    color: 'white',
    fontWeight: 500,
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  avatar: {
    borderRadius: 0,
  },
});


class Squad extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { expanded: false };
    this.expand = this.expand.bind(this);
  }


  expand() {
    this.setState(state => ({ expanded: !state.expanded }));
  }

  render() {
    const {
      manager, classes, players, openSnackbar,
      history,
    } = this.props;
    const { squad, name, totalGoals } = manager;
    const { expanded } = this.state;
    const activePicks = getActivePicks(squad);
    const inactivePicks = getInactivePicks(squad);
    const renderPlayer = (pick) => {
      const player = players[pick.id];
      const goalsForMe = getGoalsForMe(players, pick);
      const avatarSrc = getTeamBadge(player.team);
      const availability = getAvailability(player);
      return (
        <ListItem key={player.id} button onClick={() => { history.push(`/player/${player.id}`); }}>
          <Avatar src={avatarSrc} className={classes.avatar} />
          <ListItemText primary={getPlayerName(player)} secondary={`Goals: ${goalsForMe}. Total Goals: ${player.goals_scored}.`} />
          <ListItemSecondaryAction>
            { availability && (
            <IconButton aria-label="View" button onClick={() => openSnackbar(player.news)}>
              {availability}
            </IconButton>
            )
            }
          </ListItemSecondaryAction>
        </ListItem>
      );
    };
    return (
      <div className={classes.root}>
        <List
          component="nav"
          disablePadding
          subheader={<ListSubheader disableSticky className={classes.listSubheader}>{name} - {totalGoals}</ListSubheader>}
        >
          {activePicks.map(pick => renderPlayer(pick))}
          {
            inactivePicks.length > 0
            && (
            <ListItem button onClick={this.expand}>
              <ListItemText primary="Previous Players" />
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            )
          }

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <List disablePadding>
              {inactivePicks.map(pick => renderPlayer(pick))}
            </List>
          </Collapse>
        </List>
      </div>
    );
  }
}

Squad.propTypes = {
  manager: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  players: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  openSnackbar: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  openSnackbar: (message) => { dispatch(openSnackbar(message)); },
});

const styled = withStyles(styles)(Squad);
const routed = withRouter(styled);
export default connect(() => ({}), mapDispatchToProps)(routed);
